export var ClaimTypeTrigram;
(function (ClaimTypeTrigram) {
    ClaimTypeTrigram["Empty"] = "empty";
    ClaimTypeTrigram["WaterDamage"] = "DDE";
    ClaimTypeTrigram["Robbery"] = "VOL";
    ClaimTypeTrigram["Disaster"] = "CAT";
    ClaimTypeTrigram["Drought"] = "SEC";
    ClaimTypeTrigram["StormSnowHail"] = "TNG";
    ClaimTypeTrigram["Glass"] = "BDG";
    ClaimTypeTrigram["Fire"] = "INC";
    ClaimTypeTrigram["Electric"] = "ELE";
    ClaimTypeTrigram["Vehicle"] = "VEH";
    ClaimTypeTrigram["Other"] = "AUT";
})(ClaimTypeTrigram || (ClaimTypeTrigram = {}));
export var ClaimQualificationTypesTrad;
(function (ClaimQualificationTypesTrad) {
    ClaimQualificationTypesTrad["VOL"] = "robbery";
    ClaimQualificationTypesTrad["INC"] = "fire";
    ClaimQualificationTypesTrad["ELE"] = "electrical";
})(ClaimQualificationTypesTrad || (ClaimQualificationTypesTrad = {}));
export var OtherGuaranteeType;
(function (OtherGuaranteeType) {
    OtherGuaranteeType["TerrorismActs"] = "attentats-et-actes-de-terrorisme";
    OtherGuaranteeType["GlassesBreakage"] = "bris-des-glaces";
    OtherGuaranteeType["NaturalCatastrophies"] = "catastrophes-naturelles";
    OtherGuaranteeType["TechnologicalCatastrophies"] = "catastrophes-technologiques";
    OtherGuaranteeType["ElectricalDamages"] = "dommages-electriques";
    OtherGuaranteeType["DPRSA"] = "DPRSA";
    OtherGuaranteeType["HomeLegalProtection"] = "protection-juridique-habitation";
    OtherGuaranteeType["BodilyCivilLiability"] = "responsabilite-civile-corporelle";
    OtherGuaranteeType["MaterialCivilLiability"] = "responsabilite-civile-materielle";
})(OtherGuaranteeType || (OtherGuaranteeType = {}));
export var LeakageCauseLocation;
(function (LeakageCauseLocation) {
    LeakageCauseLocation["AtHome"] = "chez-moi";
    LeakageCauseLocation["Neighbour"] = "chez-un-voisin";
    LeakageCauseLocation["Commons"] = "parties-communes";
    LeakageCauseLocation["ProfessionalAjoiningLocal"] = "local-professionnel-mitoyen";
    LeakageCauseLocation["Unknown"] = "je-ne-sais-pas";
})(LeakageCauseLocation || (LeakageCauseLocation = {}));
export var LeakageCauseLocationHouse;
(function (LeakageCauseLocationHouse) {
    LeakageCauseLocationHouse["Inside"] = "interieur";
    LeakageCauseLocationHouse["Outside"] = "exterieur";
})(LeakageCauseLocationHouse || (LeakageCauseLocationHouse = {}));
export var EquipmentAge;
(function (EquipmentAge) {
    EquipmentAge["LessThan2Years"] = "moins-de-2-ans";
    EquipmentAge["Between2And10Years"] = "entre-2-et-10-ans";
    EquipmentAge["MoreThan10Years"] = "plus-de-10-Ans";
    EquipmentAge["Unknown"] = "inconnu";
})(EquipmentAge || (EquipmentAge = {}));
export var OutsiderIntervention;
(function (OutsiderIntervention) {
    OutsiderIntervention["No"] = "non";
    OutsiderIntervention["Yes"] = "oui";
    OutsiderIntervention["Unknown"] = "inconnu";
})(OutsiderIntervention || (OutsiderIntervention = {}));
export var QualificationRoomType;
(function (QualificationRoomType) {
    QualificationRoomType["Kitchen"] = "kitchen";
    QualificationRoomType["Lounge"] = "lounge";
    QualificationRoomType["Bathroom"] = "bathroom";
    QualificationRoomType["WC"] = "wc";
    QualificationRoomType["BedroomOffice"] = "bedroom-office";
    QualificationRoomType["Balcony"] = "balcony";
    QualificationRoomType["Basement"] = "basement";
    QualificationRoomType["Garage"] = "garage";
    QualificationRoomType["Laundry"] = "laundry";
    QualificationRoomType["CorridorEntrance"] = "corridor-entrance";
    QualificationRoomType["Other"] = "other";
    QualificationRoomType["Outbuilding"] = "outbuilding";
})(QualificationRoomType || (QualificationRoomType = {}));
export var LeakageCauseRoom;
(function (LeakageCauseRoom) {
    LeakageCauseRoom["Kitchen"] = "cuisine";
    LeakageCauseRoom["Bathroom"] = "salle-de-bain";
    LeakageCauseRoom["Wc"] = "wc";
    LeakageCauseRoom["Room"] = "chambre-sejour";
    LeakageCauseRoom["Corridor"] = "entree-couloir";
    LeakageCauseRoom["Other"] = "autre";
})(LeakageCauseRoom || (LeakageCauseRoom = {}));
export var QualificationEquipmentType;
(function (QualificationEquipmentType) {
    QualificationEquipmentType["Pipe"] = "pipe";
    QualificationEquipmentType["WashBasin"] = "wash_basin";
    QualificationEquipmentType["Sink"] = "sink";
    QualificationEquipmentType["Shower"] = "shower";
    QualificationEquipmentType["Bathtube"] = "bathtube";
    QualificationEquipmentType["WC"] = "wc";
    QualificationEquipmentType["Dishwasher"] = "dishwasher";
    QualificationEquipmentType["Refrigerator"] = "refrigerator";
    QualificationEquipmentType["WashingMachine"] = "washing_machine";
    QualificationEquipmentType["Radiator"] = "radiateur";
    QualificationEquipmentType["Boiler"] = "boiler";
    QualificationEquipmentType["HotWaterTank"] = "hot_water_tank";
    QualificationEquipmentType["Aquarium"] = "aquarium";
    QualificationEquipmentType["Ventilation"] = "ventilation";
})(QualificationEquipmentType || (QualificationEquipmentType = {}));
export var LeakageCauseEquipmentDetail;
(function (LeakageCauseEquipmentDetail) {
    LeakageCauseEquipmentDetail["AppliancePipe"] = "tuyau-alimentation-evacuation";
    LeakageCauseEquipmentDetail["ApplianceOverflow"] = "debordement";
    LeakageCauseEquipmentDetail["ApplianceTap"] = "robinet";
    LeakageCauseEquipmentDetail["ApplianceDrain"] = "siphon";
    LeakageCauseEquipmentDetail["BoilerCollective"] = "radiateur-collectif";
    LeakageCauseEquipmentDetail["BoilerIndividual"] = "radiateur-individuel";
    LeakageCauseEquipmentDetail["BoilerBalloon"] = "rupture-ballon-eau-chaude";
    LeakageCauseEquipmentDetail["BoilerPipe"] = "tuyau-canalisation";
    LeakageCauseEquipmentDetail["PipeAccessible"] = "canalisation-accessible";
    LeakageCauseEquipmentDetail["PipeTap"] = "robinet";
    LeakageCauseEquipmentDetail["PipeNotAccessible"] = "canalisation-non-accessible";
    LeakageCauseEquipmentDetail["WashBasinSeal"] = "joints";
    LeakageCauseEquipmentDetail["WashBasinShowerHose"] = "tuyau-de-douche";
    LeakageCauseEquipmentDetail["WashBasinDrain"] = "siphon";
    LeakageCauseEquipmentDetail["WashBasinTap"] = "robinet";
    LeakageCauseEquipmentDetail["WashBasinWaterSupply"] = "alimentation";
    LeakageCauseEquipmentDetail["WcTap"] = "robinet-et-son-alimentation";
    LeakageCauseEquipmentDetail["WcFlusher"] = "chasse-d-eau";
    LeakageCauseEquipmentDetail["WcCommonWaterDisposal"] = "evacuation-commune-bouchee";
    LeakageCauseEquipmentDetail["WcBreak"] = "rupture-corps-wc";
    LeakageCauseEquipmentDetail["WcIndividualWaterDisposal"] = "evacuation-privative-bouchee";
    LeakageCauseEquipmentDetail["Spillage"] = "renversement";
    LeakageCauseEquipmentDetail["Unknown"] = "inconnu";
})(LeakageCauseEquipmentDetail || (LeakageCauseEquipmentDetail = {}));
export var LeakageCauseCommonAreaDetail;
(function (LeakageCauseCommonAreaDetail) {
    LeakageCauseCommonAreaDetail["CommonAreaAccessiblePipe"] = "parties-commune-canalisation-accessible";
    LeakageCauseCommonAreaDetail["CommonAreaNonAccessiblePipe"] = "parties-commune-canalisation-non-accessible";
    LeakageCauseCommonAreaDetail["CommonAreaClogging"] = "parties-commune-engorgement";
    LeakageCauseCommonAreaDetail["CommonAreaUnkwnown"] = "parties-commune-inconnu";
    LeakageCauseCommonAreaDetail["CommonAreaRoofInfiltration"] = "parties-commune-infiltration-toiture";
    LeakageCauseCommonAreaDetail["CommonAreaFacadeInfiltration"] = "parties-commune-infiltration-facade";
    LeakageCauseCommonAreaDetail["CommonAreaBuriedPipe"] = "parties-commune-canalisation-enterree";
    LeakageCauseCommonAreaDetail["CommonAreaGutter"] = "parties-commune-gouttiere";
    LeakageCauseCommonAreaDetail["CommonAreaConduitFlux"] = "parties-commune-ecoulement-conduit";
})(LeakageCauseCommonAreaDetail || (LeakageCauseCommonAreaDetail = {}));
export var LeakageCauseOutsideHouseDetail;
(function (LeakageCauseOutsideHouseDetail) {
    LeakageCauseOutsideHouseDetail["OutsideHouseGardenGround"] = "exterieur-maison-jardin-cour";
    LeakageCauseOutsideHouseDetail["OutsideHouseBalconyTerrace"] = "exterieur-maison-balcon-terrasse";
    LeakageCauseOutsideHouseDetail["OutsideHouseGarage"] = "exterieur-maison-garage";
    LeakageCauseOutsideHouseDetail["OutsideHouseAccessiblePipe"] = "exterieur-maison-canalisation-accessible";
    LeakageCauseOutsideHouseDetail["OutsideHouseClogging"] = "exterieur-maison-engorgement";
    LeakageCauseOutsideHouseDetail["OutsideHouseGutter"] = "exterieur-maison-gouttiere";
    LeakageCauseOutsideHouseDetail["OutsideHouseFacadeInfiltration"] = "exterieur-maison-infiltration-facade";
    LeakageCauseOutsideHouseDetail["OutsideHouseRoofInfiltration"] = "exterieur-maison-infiltration-toiture";
    LeakageCauseOutsideHouseDetail["OutsideHouseConduitFlux"] = "exterieur-maison-ecoulement-conduit";
    LeakageCauseOutsideHouseDetail["OutsideHouseSwimmingpool"] = "exterieur-maison-piscine";
    LeakageCauseOutsideHouseDetail["OutsideHouseBuriedPipe"] = "exterieur-maison-canalisation-enterree";
    LeakageCauseOutsideHouseDetail["OutsideHouseUnkwnown"] = "exterieur-maison-inconnu";
    LeakageCauseOutsideHouseDetail["OutsideHouseOutbuilding"] = "exterieur-maison-dependance";
    LeakageCauseOutsideHouseDetail["OutsideHouseOther"] = "exterieur-maison-autre";
})(LeakageCauseOutsideHouseDetail || (LeakageCauseOutsideHouseDetail = {}));
export var LeakageCauseFixed;
(function (LeakageCauseFixed) {
    LeakageCauseFixed["YES"] = "yes";
    LeakageCauseFixed["NO"] = "no";
    LeakageCauseFixed["IN_PROGRESS"] = "repair-in-progress";
})(LeakageCauseFixed || (LeakageCauseFixed = {}));
export var ElectricalDamageCauseOrigin;
(function (ElectricalDamageCauseOrigin) {
    ElectricalDamageCauseOrigin["AtHome"] = "chez-moi";
    ElectricalDamageCauseOrigin["Network"] = "reseau-public";
    ElectricalDamageCauseOrigin["Thunder"] = "orage-foudre";
    ElectricalDamageCauseOrigin["Unknown"] = "je-ne-sais-pas";
})(ElectricalDamageCauseOrigin || (ElectricalDamageCauseOrigin = {}));
export var StormSnowHailDamageCauseType;
(function (StormSnowHailDamageCauseType) {
    StormSnowHailDamageCauseType["Storm"] = "storm";
    StormSnowHailDamageCauseType["Snow"] = "snow";
    StormSnowHailDamageCauseType["Hail"] = "hail";
})(StormSnowHailDamageCauseType || (StormSnowHailDamageCauseType = {}));
export var RobberyMethod;
(function (RobberyMethod) {
    RobberyMethod["Sneaking"] = "introduction-clandestine";
    RobberyMethod["Climbing"] = "escalade";
    RobberyMethod["FalseKey"] = "fausse-cle";
    RobberyMethod["FalseIdentity"] = "usurpation-identite";
    RobberyMethod["Assault"] = "agression";
    RobberyMethod["ElectronicHacking"] = "piratage-electronique";
    RobberyMethod["MagneticPass"] = "pass-magnetique";
    RobberyMethod["Other"] = "autre";
})(RobberyMethod || (RobberyMethod = {}));
export var RobberyBreakableElement;
(function (RobberyBreakableElement) {
    RobberyBreakableElement["Door"] = "porte";
    RobberyBreakableElement["GarageDoor"] = "porte-garage";
    RobberyBreakableElement["Shutter"] = "volet";
    RobberyBreakableElement["Window"] = "fenetre";
    RobberyBreakableElement["FrenchWindow"] = "porte-fenetre";
    RobberyBreakableElement["SlidingBayWindow"] = "baie-coulissante";
    RobberyBreakableElement["Fence"] = "cloture";
    RobberyBreakableElement["Gate"] = "portail";
    RobberyBreakableElement["LittleGate"] = "portillon";
    RobberyBreakableElement["Other"] = "autre";
})(RobberyBreakableElement || (RobberyBreakableElement = {}));
export var RobberyLocation;
(function (RobberyLocation) {
    RobberyLocation["Home"] = "domicile";
    RobberyLocation["Outside"] = "villegiature";
})(RobberyLocation || (RobberyLocation = {}));
export var RobberyEventType;
(function (RobberyEventType) {
    RobberyEventType["Robbery"] = "robbery";
    RobberyEventType["Vandalism"] = "vandalism";
    RobberyEventType["LostKeys"] = "lost-keys";
})(RobberyEventType || (RobberyEventType = {}));
export var RobberyType;
(function (RobberyType) {
    RobberyType["AnyRobbery"] = "any-robbery";
    RobberyType["Robbery"] = "robbery";
    RobberyType["BreakInRobbery"] = "break-in-robbery";
    RobberyType["BreakInAttemptedRobbery"] = "break-in-attempted-robbery";
    RobberyType["PersonalRobbery"] = "personal-robbery";
})(RobberyType || (RobberyType = {}));
export var PersonalRobberyEventType;
(function (PersonalRobberyEventType) {
    PersonalRobberyEventType["Assault"] = "assault";
    PersonalRobberyEventType["Accident"] = "accident";
    PersonalRobberyEventType["Other"] = "other";
})(PersonalRobberyEventType || (PersonalRobberyEventType = {}));
export var PersonalRobberyConcernedPerson;
(function (PersonalRobberyConcernedPerson) {
    PersonalRobberyConcernedPerson["Yourself"] = "yourself";
    PersonalRobberyConcernedPerson["Children"] = "children";
    PersonalRobberyConcernedPerson["Other"] = "other";
})(PersonalRobberyConcernedPerson || (PersonalRobberyConcernedPerson = {}));
export var RobberySecurizationMethod;
(function (RobberySecurizationMethod) {
    RobberySecurizationMethod["Unnecessary"] = "non-necessaire";
    RobberySecurizationMethod["SecuredByMyself"] = "securise-par-moi-meme";
    RobberySecurizationMethod["SecuredByAssistance"] = "securise-par-assistance";
    RobberySecurizationMethod["AssistanceNeeded"] = "besoin-assistance";
})(RobberySecurizationMethod || (RobberySecurizationMethod = {}));
export var RobberySecurizationYesOrNo;
(function (RobberySecurizationYesOrNo) {
    RobberySecurizationYesOrNo["Yes"] = "yes";
    RobberySecurizationYesOrNo["No"] = "No";
})(RobberySecurizationYesOrNo || (RobberySecurizationYesOrNo = {}));
export var BreakInDamageRepairStatus;
(function (BreakInDamageRepairStatus) {
    BreakInDamageRepairStatus["Repaired"] = "repaired";
    BreakInDamageRepairStatus["RepairedButNeedsMoreWork"] = "repaired-but-needs-more-work";
    BreakInDamageRepairStatus["NotRepaired"] = "not-repaired";
})(BreakInDamageRepairStatus || (BreakInDamageRepairStatus = {}));
export var BreakInDamageRepairPerson;
(function (BreakInDamageRepairPerson) {
    BreakInDamageRepairPerson["Myself"] = "myself";
    BreakInDamageRepairPerson["Artisan"] = "artisan";
    BreakInDamageRepairPerson["Assistance"] = "assistance";
})(BreakInDamageRepairPerson || (BreakInDamageRepairPerson = {}));
export var BreakInDamageRepairationType;
(function (BreakInDamageRepairationType) {
    BreakInDamageRepairationType["Temporary"] = "temporary";
    BreakInDamageRepairationType["Definitive"] = "definitive";
    BreakInDamageRepairationType["AssistanceTemporary"] = "assistance-temporary";
    BreakInDamageRepairationType["AssistanceDefinitive"] = "assistance-definitive";
})(BreakInDamageRepairationType || (BreakInDamageRepairationType = {}));
export var BreakInDamageRepairQuotePendingStatus;
(function (BreakInDamageRepairQuotePendingStatus) {
    BreakInDamageRepairQuotePendingStatus["Initial"] = "initial";
    BreakInDamageRepairQuotePendingStatus["Late"] = "late";
    BreakInDamageRepairQuotePendingStatus["Both"] = "both";
    BreakInDamageRepairQuotePendingStatus["None"] = "none";
})(BreakInDamageRepairQuotePendingStatus || (BreakInDamageRepairQuotePendingStatus = {}));
export var LostKeysLockType;
(function (LostKeysLockType) {
    LostKeysLockType["MainEntranceDoor"] = "porte-entree-principale";
    LostKeysLockType["SecondaryEntranceDoor"] = "porte-entree-secondaire";
    LostKeysLockType["WindowDoor"] = "porte-fenetre";
    LostKeysLockType["GarageDoor"] = "porte-de-garage";
    LostKeysLockType["BasementDoor"] = "porte-de-cave";
    LostKeysLockType["Gate"] = "portail-portilon";
    LostKeysLockType["EntranceDoorCommonArea"] = "porte-entree au-parties-communes";
    LostKeysLockType["InteriorDoor"] = "porte-interieure";
    LostKeysLockType["Car"] = "vehicule";
    LostKeysLockType["GeneraliCarInsured"] = "vehicule-assure-generali";
    LostKeysLockType["GeneraliCarNotInsured"] = "vehicule-non-assure-generali";
    LostKeysLockType["MailboxDoor"] = "porte-de-boites-a-lettres";
    LostKeysLockType["Other"] = "autre";
})(LostKeysLockType || (LostKeysLockType = {}));
export var FireBeginLocation;
(function (FireBeginLocation) {
    FireBeginLocation["AtHome"] = "chez-moi";
    FireBeginLocation["Neighbour"] = "chez-un-voisin";
    FireBeginLocation["Commons"] = "parties-communes";
    FireBeginLocation["ProfessionalAjoiningLocal"] = "local-professionnel-mitoyen";
    FireBeginLocation["Vehicle"] = "vehicule-d-un-tiers";
    FireBeginLocation["Unknown"] = "je-ne-sais-pas";
})(FireBeginLocation || (FireBeginLocation = {}));
export var FireBeginLocationHouse;
(function (FireBeginLocationHouse) {
    FireBeginLocationHouse["Inside"] = "interieur";
    FireBeginLocationHouse["Outside"] = "exterieur";
})(FireBeginLocationHouse || (FireBeginLocationHouse = {}));
export var FireCauseLocalOrigin;
(function (FireCauseLocalOrigin) {
    FireCauseLocalOrigin["Inattention"] = "maladresse-inattention";
    FireCauseLocalOrigin["ElectricalProblem"] = "probleme-electrique";
    FireCauseLocalOrigin["HotWorks"] = "travaux";
    FireCauseLocalOrigin["FirePlace"] = "cheminee-poele";
    FireCauseLocalOrigin["CriminalAct"] = "acte-criminel";
    FireCauseLocalOrigin["Lightning"] = "foudre";
    FireCauseLocalOrigin["dontKnow"] = "je-ne-sais-pas";
})(FireCauseLocalOrigin || (FireCauseLocalOrigin = {}));
export var FireCauseExternOrigin;
(function (FireCauseExternOrigin) {
    FireCauseExternOrigin["Barbecue"] = "barbecue-cigarette-cendrier";
    FireCauseExternOrigin["Lightning"] = "foudre";
    FireCauseExternOrigin["Tinkering"] = "travaux-bricolage-jardinage";
    FireCauseExternOrigin["ForestFire"] = "feu-de-foret-detritus";
    FireCauseExternOrigin["dontKnow"] = "je-ne-sais-pas";
})(FireCauseExternOrigin || (FireCauseExternOrigin = {}));
export var FireCauseCommonPartsOrigin;
(function (FireCauseCommonPartsOrigin) {
    FireCauseCommonPartsOrigin["Enedis"] = "enedis";
    FireCauseCommonPartsOrigin["ElectricalProblem"] = "probleme-electrique";
    FireCauseCommonPartsOrigin["Company"] = "entreprise";
    FireCauseCommonPartsOrigin["CriminalAct"] = "acte-criminel";
    FireCauseCommonPartsOrigin["dontKnow"] = "je-ne-sais-pas";
})(FireCauseCommonPartsOrigin || (FireCauseCommonPartsOrigin = {}));
export var FireHasRealEstateDamages;
(function (FireHasRealEstateDamages) {
    FireHasRealEstateDamages["Inside"] = "inside";
    FireHasRealEstateDamages["Outside"] = "outside";
    FireHasRealEstateDamages["Both"] = "both";
    FireHasRealEstateDamages["None"] = "none";
})(FireHasRealEstateDamages || (FireHasRealEstateDamages = {}));
export var FireElectricalCause;
(function (FireElectricalCause) {
    FireElectricalCause["electricMeter"] = "compteur";
    FireElectricalCause["electricalDevice"] = "appareil-electrique";
    FireElectricalCause["electricalBoard"] = "tableau-electrique";
    FireElectricalCause["electricalNetwork"] = "reseau-electrique";
    FireElectricalCause["dontKnow"] = "je-ne-sais-pas";
})(FireElectricalCause || (FireElectricalCause = {}));
export var FireWhoMakesWorks;
(function (FireWhoMakesWorks) {
    FireWhoMakesWorks["myself"] = "moi-meme";
    FireWhoMakesWorks["particular"] = "particulier";
    FireWhoMakesWorks["professional"] = "professionnel";
})(FireWhoMakesWorks || (FireWhoMakesWorks = {}));
export var FireWhoIsResponsibleBarbecue;
(function (FireWhoIsResponsibleBarbecue) {
    FireWhoIsResponsibleBarbecue["myself"] = "moi-meme";
    FireWhoIsResponsibleBarbecue["particular"] = "particulier";
})(FireWhoIsResponsibleBarbecue || (FireWhoIsResponsibleBarbecue = {}));
export var FireElectricalBoardAge;
(function (FireElectricalBoardAge) {
    FireElectricalBoardAge["yes"] = "oui";
    FireElectricalBoardAge["no"] = "non";
    FireElectricalBoardAge["dontKnow"] = "je-ne-sais-pas";
})(FireElectricalBoardAge || (FireElectricalBoardAge = {}));
export var FireElectricalProfessionalIntervention;
(function (FireElectricalProfessionalIntervention) {
    FireElectricalProfessionalIntervention["yes"] = "oui";
    FireElectricalProfessionalIntervention["no"] = "non";
})(FireElectricalProfessionalIntervention || (FireElectricalProfessionalIntervention = {}));
export var FireFireplaceCause;
(function (FireFireplaceCause) {
    FireFireplaceCause["equipment"] = "equipement";
    FireFireplaceCause["sparks"] = "etincelle";
    FireFireplaceCause["dontKnow"] = "je-ne-sais-pas";
})(FireFireplaceCause || (FireFireplaceCause = {}));
export var FireFireplaceAge;
(function (FireFireplaceAge) {
    FireFireplaceAge["yes"] = "oui";
    FireFireplaceAge["no"] = "non";
    FireFireplaceAge["dontKnow"] = "je-ne-sais-pas";
})(FireFireplaceAge || (FireFireplaceAge = {}));
export var FireFireplaceWhoMakesWorks;
(function (FireFireplaceWhoMakesWorks) {
    FireFireplaceWhoMakesWorks["myself"] = "moi-meme";
    FireFireplaceWhoMakesWorks["particular"] = "particulier";
    FireFireplaceWhoMakesWorks["professional"] = "professionnel";
    FireFireplaceWhoMakesWorks["dontKnow"] = "je-ne-sais-pas";
})(FireFireplaceWhoMakesWorks || (FireFireplaceWhoMakesWorks = {}));
export var FireCauseNeighboorOrigin;
(function (FireCauseNeighboorOrigin) {
    FireCauseNeighboorOrigin["Inattention"] = "maladresse-inattention";
    FireCauseNeighboorOrigin["ElectricalProblem"] = "probleme-electrique";
    FireCauseNeighboorOrigin["HotWorks"] = "travaux";
    FireCauseNeighboorOrigin["FirePlace"] = "cheminee-poele";
    FireCauseNeighboorOrigin["CriminalAct"] = "acte-criminel";
    FireCauseNeighboorOrigin["NaturalOrigin"] = "origine-naturelle";
    FireCauseNeighboorOrigin["Other"] = "autre";
    FireCauseNeighboorOrigin["dontKnow"] = "je-ne-sais-pas";
})(FireCauseNeighboorOrigin || (FireCauseNeighboorOrigin = {}));
export var ExitTypes;
(function (ExitTypes) {
    ExitTypes["NotHandledExit"] = "indisponible";
    ExitTypes["OnSiteAppointment"] = "ESS";
    ExitTypes["VisioDistanceAppointment"] = "EAD_VISIO";
    ExitTypes["PhoneDistanceAppointment"] = "EAD_TEL";
    ExitTypes["ImmediatePayoutImpossible"] = "reglement_immediat_impossible";
    ExitTypes["LeakageMustBeLocalized"] = "doit_localiser_la_fuite";
    ExitTypes["LeakageMustBeRepaired"] = "doit_reparer_la_fuite";
    ExitTypes["BeCalled"] = "appel_par_un_conseiller";
    ExitTypes["HelpNeededToFindLeakage"] = "recherche_de_fuite";
    ExitTypes["InsuranceDateIntervalOver"] = "delai_declaration_depasse";
    ExitTypes["NotCovered"] = "non_couvert";
    ExitTypes["Indemnisation"] = "Indemnisation";
    ExitTypes["IndemnisationDenied"] = "refus_indemnisation";
    ExitTypes["NoFollowUp"] = "no_follow_up";
    ExitTypes["TenantInsurer"] = "assurance_locataire";
    ExitTypes["OwnerInsurer"] = "assurance_proprietaire";
    ExitTypes["SelfRepair"] = "gre_a_gre";
    ExitTypes["Expertise"] = "expertise";
    ExitTypes["CertifiedArtisan"] = "remuneration_en_nature";
    ExitTypes["IndemnisationPartial"] = "indemnisation_partielle";
    ExitTypes["IndemnisationComplete"] = "indemnisation_complete";
    ExitTypes["NoFollowUpExclusion"] = "no_follow_up_exclusion";
    ExitTypes["NoFollowUpNoClientAnswer"] = "no_follow_up_pas_retour_client";
    ExitTypes["NoFollowUpConventional"] = "no_follow_up_conventionnel";
    ExitTypes["NoFollowUpDeductible"] = "no_follow_up_franchise";
    ExitTypes["TransferOutisideScope"] = "transfert_hors_perimetre";
    ExitTypes["Unknown"] = "non_determinee";
    ExitTypes["ExpertiseByManagerInsurer"] = "expertise_par_assureur_gestionnaire";
    ExitTypes["InManagement"] = "reprise_en_gestion";
})(ExitTypes || (ExitTypes = {}));
export var ExpertAppointmentMode;
(function (ExpertAppointmentMode) {
    ExpertAppointmentMode["Visio"] = "Visio";
    ExpertAppointmentMode["Distance"] = "EAD";
    ExpertAppointmentMode["OnSite"] = "ESS";
})(ExpertAppointmentMode || (ExpertAppointmentMode = {}));
export const OTHER_GUARANTEE_TYPE_TRAD_KEYS = {
    [OtherGuaranteeType.TerrorismActs]: 'other_guarantee_type.terrorism_acts',
    [OtherGuaranteeType.GlassesBreakage]: 'other_guarantee_type.glasses_breakage',
    [OtherGuaranteeType.NaturalCatastrophies]: 'other_guarantee_type.natural_catastrophies',
    [OtherGuaranteeType.TechnologicalCatastrophies]: 'other_guarantee_type.technological_catastrophies',
    [OtherGuaranteeType.ElectricalDamages]: 'other_guarantee_type.electrical_damages',
    [OtherGuaranteeType.DPRSA]: 'other_guarantee_type.dprsa',
    [OtherGuaranteeType.HomeLegalProtection]: 'other_guarantee_type.home_legal_protection',
    [OtherGuaranteeType.BodilyCivilLiability]: 'other_guarantee_type.bodily_civil_liability',
    [OtherGuaranteeType.MaterialCivilLiability]: 'other_guarantee_type.material_civil_liability',
};
export const EXPERT_APPOINTMENT_TRAD_KEYS = {
    [ExpertAppointmentMode.OnSite]: 'appointment.expert.type.ESS',
    [ExpertAppointmentMode.Distance]: 'appointment.expert.type.EADTel',
    [ExpertAppointmentMode.Visio]: 'appointment.expert.type.EADVisio',
};
export var ArtisanAppointmentLabel;
(function (ArtisanAppointmentLabel) {
    ArtisanAppointmentLabel["Repair"] = "repair";
    ArtisanAppointmentLabel["LeakageCauseSearch"] = "leakage_cause_search";
    ArtisanAppointmentLabel["RobberySecuring"] = "robbery_securing";
})(ArtisanAppointmentLabel || (ArtisanAppointmentLabel = {}));
export var AllowanceUnit;
(function (AllowanceUnit) {
    AllowanceUnit["Surface"] = "surface";
    AllowanceUnit["Fixed"] = "fixed";
    AllowanceUnit["Percentage"] = "percentage";
    AllowanceUnit["Length"] = "length";
    AllowanceUnit["Supply"] = "supply";
})(AllowanceUnit || (AllowanceUnit = {}));
export var AllowanceUnitSymbol;
(function (AllowanceUnitSymbol) {
    AllowanceUnitSymbol["Euro"] = "\u20AC";
    AllowanceUnitSymbol["EuroPerMeter"] = "\u20AC/m";
    AllowanceUnitSymbol["EuroPerMeterSquared"] = "\u20AC/m\u00B2";
    AllowanceUnitSymbol["Percentage"] = "%";
})(AllowanceUnitSymbol || (AllowanceUnitSymbol = {}));
export var ClaimClosingReason;
(function (ClaimClosingReason) {
    ClaimClosingReason["SelfRepair"] = "gr\u00E9_\u00E0_gr\u00E9";
    ClaimClosingReason["CertifiedArtisan"] = "r\u00E9mun\u00E9ration_en_nature";
    ClaimClosingReason["PartialPecuniary"] = "indemnisation_partielle";
    ClaimClosingReason["FullPecuniary"] = "indemnisation_totale";
    ClaimClosingReason["NoFollowUpExclusion"] = "sans_suite_exclusion";
    ClaimClosingReason["NoFollowUpNoCustomerFeedback"] = "sans_suite_pas_retour_client";
    ClaimClosingReason["NoFollowUpConventional"] = "sans_suite_conventionnel";
    ClaimClosingReason["NoFollowUpDeductible"] = "sans_suite_franchise";
    ClaimClosingReason["NoFollowUpTransfert"] = "sans_suite_transfert";
})(ClaimClosingReason || (ClaimClosingReason = {}));
export const ALLOWANCE_UNIT_SYMBOL = {
    [AllowanceUnit.Surface]: AllowanceUnitSymbol.EuroPerMeterSquared,
    [AllowanceUnit.Fixed]: AllowanceUnitSymbol.Euro,
    [AllowanceUnit.Percentage]: AllowanceUnitSymbol.Percentage,
    [AllowanceUnit.Length]: AllowanceUnitSymbol.EuroPerMeter,
    [AllowanceUnit.Supply]: AllowanceUnitSymbol.Euro,
};
export const CLAIM_QUALIFICATION_KEY = {
    [ClaimTypeTrigram.Empty]: null,
    [ClaimTypeTrigram.WaterDamage]: 'waterDamageQualification',
    [ClaimTypeTrigram.Robbery]: 'robberyDamageQualification',
    [ClaimTypeTrigram.Drought]: 'droughtDamageQualification',
    [ClaimTypeTrigram.Disaster]: null,
    [ClaimTypeTrigram.StormSnowHail]: 'stormSnowHailDamageQualification',
    [ClaimTypeTrigram.Glass]: 'glassBreakageDamageQualification',
    [ClaimTypeTrigram.Fire]: 'fireDamageQualification',
    [ClaimTypeTrigram.Electric]: 'electricalDamageQualification',
    [ClaimTypeTrigram.Vehicle]: null,
    [ClaimTypeTrigram.Other]: null,
};
export var CompensationMode;
(function (CompensationMode) {
    CompensationMode["CompensationModeNone"] = "none";
    CompensationMode["CompensationModePecuniary"] = "pecuniary";
    CompensationMode["CompensationModeSelfRepair"] = "self-repair";
    CompensationMode["CompensationModeCertifiedArtisan"] = "certified-artisan";
})(CompensationMode || (CompensationMode = {}));
export var ClaimCompensationOption;
(function (ClaimCompensationOption) {
    ClaimCompensationOption["SelfRepair"] = "self-repair";
    ClaimCompensationOption["PartnerArtisan"] = "certified-artisan";
    ClaimCompensationOption["OtherArtisan"] = "pecuniary";
})(ClaimCompensationOption || (ClaimCompensationOption = {}));
export var ClaimStatus;
(function (ClaimStatus) {
    ClaimStatus["Opened"] = "opened";
    ClaimStatus["DamagesValidated"] = "damages_validated";
    ClaimStatus["DamagesPreValidated"] = "damages_pre_validated";
    ClaimStatus["ImmediatePayoutPending"] = "payout_pending";
    ClaimStatus["DelayedPayoutPending"] = "delayed_payout_pending";
    ClaimStatus["Completed"] = "completed";
    ClaimStatus["CompensationDocumentPending"] = "invoice_pending";
    ClaimStatus["DeclarationDocumentPending"] = "declaration_document_pending";
    ClaimStatus["ExpertAppointmentConfirmed"] = "expert_appointment_confirmed";
    ClaimStatus["ArtisanAppointmentConfirmed"] = "artisan_appointment_confirmed";
    ClaimStatus["ArtisanCounterOfferRejected"] = "artisan_counter_offer_rejected";
    ClaimStatus["NotCovered"] = "not_covered";
    ClaimStatus["NotCoveredDateIntervalOver"] = "not_covered_insurance_date_interval_over";
    ClaimStatus["NoAdministrativeFollowUp"] = "no_administrative_follow_up";
    // TODO: change the name of this aberration
    ClaimStatus["TransferToPae"] = "no_follow_up";
    ClaimStatus["NoSuitableExpertiseSchedule"] = "no_suitable_expertise_schedule";
    ClaimStatus["Expert"] = "expert";
    ClaimStatus["ExpertPending"] = "expert_pending";
    ClaimStatus["REN"] = "ren";
    ClaimStatus["RENPending"] = "ren_pending";
    ClaimStatus["ClientPending"] = "client_pending";
    ClaimStatus["GoPay"] = "go_pay";
    ClaimStatus["Closed"] = "closed";
    ClaimStatus["ClosureConfirmed"] = "closure_confirmed";
    ClaimStatus["Created"] = "created";
})(ClaimStatus || (ClaimStatus = {}));
export var IrsiConventionTier;
(function (IrsiConventionTier) {
    IrsiConventionTier["None"] = "sans";
    IrsiConventionTier["Irsi1"] = "IRSI tranche 1";
    IrsiConventionTier["Irsi2"] = "IRSI tranche 2";
})(IrsiConventionTier || (IrsiConventionTier = {}));
export var WarningType;
(function (WarningType) {
    WarningType["WARNING"] = "warning";
})(WarningType || (WarningType = {}));
export var RefusedRuleDetailType;
(function (RefusedRuleDetailType) {
    RefusedRuleDetailType["TOTAL"] = "TOTAL";
    RefusedRuleDetailType["PARTIAL"] = "PARTIAL";
})(RefusedRuleDetailType || (RefusedRuleDetailType = {}));
